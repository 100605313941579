/* http://meyerweb.com/eric/tools/css/reset/ 2. v2.0 | 20110126
  License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}
ol, ul {
  list-style: none;
}
blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/*
  ========================================
  Custom styles
  ========================================
*/
@media (max-width: 1200px) {
  body {
    font-size: 1.3vw;
  }
}

@media (min-width: 1201px) {
  body {
    font-size: 16px;
  }
}

body {
  background: black;
  color: #888;
  font-family: "Rajdhani";
  line-height: 1.2;
}

h1, h2, h3, h4 {
  color: #aaa;
}

h1 {
  font-size: 400%;
  font-weight: 700;
}
h2 {
  color: #0a0;
  font-size: 200%;
  font-weight: 600;
}
h3 {
  font-size: 150%;
}
h4 {
  font-size: 105%;
}
a {
  color: #648880;
  text-decoration: none;
}
a:hover {
  color: #a9b2b9;
}
p a {
  border-bottom: 1px solid #dfe2e5;
}
img {
  overflow: hidden;
}
input, textarea, keygen, select, button {
  font-size: 100%;
}

/*
  ========================================
  Components
  ========================================
*/

#app {
  max-width: 1200px;
  height: auto;
  margin-left: auto;
  margin-right: auto;
}

#header img {
  max-width: 100%;
}

#logo {
  display: inline-block;
  background: grey;
  box-sizing: border-box;
  border-style: outset;
  text-align: center;
  border-width: 3px;
  width: 100%;
}

#logo img {
  display: inline-block;
  height: 6em;
  width: 6em;
  padding: 2%;
}

#logo h1 {
  display: inline-block;
  vertical-align: text-bottom;
  color: black;
  font-size: 500%;
  margin-bottom: 2%;
}

#BG {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.block {
  display: inline-block;
  width: 100px;
  height: 100px;
}

#navBar {
  box-sizing: border-box;
  text-align: center;
  background-color: #222;
  width: 96%;
  margin-left: auto;
  margin-right: auto;
}

.sectionLabel {
  text-align: center;
  margin-top: 7em;
  height: 12em;
}

.sectionLabel h1 {
  position: absolute;
  text-align: center;
  left: 50%;
  transform: translate(-50%)
}

.sectionLabel img {
  transform: translate(0, 90%);
  width: 80%;
}

.categoryIcon {
  cursor: pointer;
  display: inline-block;
  box-sizing: border-box;
  padding: 1em;
  width: 16%;
  height: auto;
  margin: 2%;
  border-width: 3px;
  border-style: outset;
  border-color: #222;
  background-color: #333;
}

.categoryIcon h3 {
  color: #0a0;
  font-weight: 600;
}

.categoryIcon:hover {
  background: #444;
  border-color: #555;
}

.categoryIcon img {
  width: 80%;
  height: auto;
}

#navBar img {
  max-width: 50%;
  height: auto;
  margin-bottom: 0px;
}

#navBar.stick {
  margin-top: 0 !important;
  position: fixed;
  top: 0;
  z-index: 10000;
  left: 0;
  right: 0;
  max-width: 1150px;
  margin-left: auto;
  margin-right: auto;
}

.Card, .Project {
  box-sizing: border-box;
  background-clip: padding-box;
  position: relative;
  text-align: center;
  padding: 1em;
  margin: 1em;
}

.Card {
  display: inline-block;
  vertical-align: top;
  border-bottom: 0px;
  margin-left: 2.65%;
  margin-right: 2.65%;
  width: 28%;
}

#projects {
  overflow: hidden;
}

.Project {
  background-color: rgba(1,1,1,.5);
  position: relative;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
  margin-top: 3em;
  box-shadow: 2px 2px 3px rgba(1,1,1,.3),
    -2px 2px 3px rgba(1,1,1,.3),
    2px -2px 3px rgba(1,1,1,.3),
    -2px -2px 3px rgba(1,1,1,.3);
}

li {
  list-style-type: disc;
  list-style-position: inside;
  color: #5bf;
  font-size: 150%;
  font-weight: 400;
}

.projectCopy {
  box-sizing: border-box;
  padding: 1em;
  border-width: 3px;
  border-style: outset;
  border-color: #111;
  text-align: left;
  margin-top: 3em;
  margin-left: auto;
  margin-right: auto;
  width: 80%;
  background-color: #222;
}

.imageHolder {
  height: 32em;
  width: 100%;
}

.projectImg {
  max-height: 30em;
  max-width: 75%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -57%);
}

.projectNav {
  cursor: pointer;
  margin: auto;
  margin-top: 20%;
  position: absolute;
}

.projectNav img {
  max-height: 5em;
  width: auto;
}

#rightBtn {
  right: 2%;
}

.Card img {
  max-height: 7em;
  width: auto;
}

/*
  ========================================
  Animations
  ========================================
*/

.pageChange-enter {
  opacity: 0.01;
  right: 20em;
}

.pageChange-enter.pageChange-enter-active {
  right: 0;
  opacity: 1;
  transition: opacity 500ms ease-out, right 500ms ease-out;
}

.pageChange-leave {
  right: 0;
  opacity: 1;
}

.pageChange-leave.pageChange-leave-active {
  right: -20em;
  opacity: 0.01;
  transition: opacity 500ms ease-in, right 500ms ease-in;
}
